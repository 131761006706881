<script setup>
import Heading from '@/components/Heading.vue'
import Calender from '@/components/Calendar.vue'
import StampCodeInput from '@/components/StampCodeInput.vue'

import { supabase } from '@/utils/supabase.js'
import { useStampStore } from '@/stores/stamp'
import { ref, inject, onMounted } from 'vue'

// injectからdayjsを呼び出す
const dayjs = inject('dayjs')
const store = useStampStore()

// NOTE: まずはベータ版として、8月固定にする
const date = dayjs('2024-08-01')

const okyami = ref(null)
const user_name = ref('')

user_name.value = store.auth.user.user_metadata.user_name

onMounted(() => {
  setInterval(() => {
    okyami.value.classList.add('move_okyami')
    setTimeout(() => {
      okyami.value.classList.remove('move_okyami')
    }, 1500)
  }, 30000)
})


const signOut = async () => {
  const { error } = await supabase.auth.signOut()
  if (error) {
    console.error('サインアウトエラー:', error.message)
  } else {
    console.log('サインアウト成功')
  }
}

const stampCleaDay = ref([])
const getCleaDays = async () => {
  const { data:date_data, error } = await supabase
      .from('stamp_clear_dates')
      .select('id, clear_date, stamps(*)')
      .gte('clear_date', date.startOf('month').format('YYYY-MM-DD'))
      .lte('clear_date', date.endOf('month').format('YYYY-MM-DD'))
      .eq('user_id', store.auth.user.id)

  stampCleaDay.value = date_data
}
getCleaDays()


// 配列ないの数字を使って確率計算し、スタンプを抽選する
const getStampId = async () => {
  let {data:stamps, error} = await supabase
      .from('stamps')
      .select()
      .is('deleted_at', null)

  if (error) {
    // TODO: エラー処理ちゃんとする
    console.error('エラー:', error.message)
  }

  // 確率計算
  const total = stamps.reduce((acc, stamp) => acc + stamp.probability, 0)
  const random = Math.random() * total

  let sum = 0
  for (let i = 0; i < stamps.length; i++) {
    sum += stamps[i].probability
    if (random < sum) {
      return stamps[i].id
    }
  }
}

const isCheckCodeError = ref(false)
const submitCheckCode = async (c) => {
  isCheckCodeError.value = false

  let { data:stamp_codes, error } = await supabase
      .from('stamp_codes')
      .select()
      .eq('code', c)

  if (error) {
    isCheckCodeError.value = true
  } else {
    if (stamp_codes.length === 0) {
      isCheckCodeError.value = true
    } else {
      // スタンプを抽選
      const stamp_id = await getStampId()
      // DBにインサート
      await insertStampClearDate(stamp_codes[0], stamp_id)

      if (!isCheckCodeError.value) {
        controlCodeModal()
      }
    }
  }
}

const insertStampClearDate = async (code, stamp_id) => {
  const user_info = store.auth.user
  isCheckCodeError.value = false

  const { data, error } = await supabase
      .from('stamp_clear_dates')
      .insert([
        {
          user_id: user_info.id,
          code_id: code.id,
          clear_date: dayjs(code.target_date),
          stamp_id: stamp_id
        }
      ])
      .select()

  if (error) {
    isCheckCodeError.value = true
  } else {
    await getCleaDays()
  }
}

const isCodeModalOpen = ref(false)
const controlCodeModal = () => {
  isCodeModalOpen.value = !isCodeModalOpen.value
  isCheckCodeError.value = false
}
</script>

<template>
  <div v-if="false">
    <button @click="signOut">サインあうと</button>
  </div>

  <Heading :date="date" :user-name="user_name" />

  <Calender class="mt-[-0.5rem] pl-2 pr-2" :date="date" :stamp-clea-day="stampCleaDay" />

  <div class="fixed bottom-[-20px] left-0 right-0 -z-50">
    <div class="w-60 ml-auto mr-auto">
      <img src="@/assets/images/img_nyan.svg" alt="にゃんぴー" class="w-full h-auto">
    </div>
  </div>

  <div @click="controlCodeModal" class="fixed bottom-9 right-0 z-0" ref="okyami">
    <div class="w-32 h-28 bg-gradient-to-b from-[#00D1FF] to-[#FFEB5F] rounded-l-[14px] border-[1px] border-white drop-shadow-[0_2px_1px_rgba(0,0,0,0.25)]">
      <div class="relative h-full">
        <div class="absolute bottom-3 left-0 right-0">
          <div class="relative">
            <p class="text-2xl text-center"
               style="text-shadow:
                      3px 3px 1px white, -3px -3px 1px white,
                      -3px 3px 1px white, 3px -3px 1px white,
                      3px 0px 1px white, -3px -0px 1px white,
                      0px 3px 1px white, 0px -3px 1px white;"
            >スタンプおす?</p>

            <div class="absolute bottom-6 left-0 right-0">
              <div class="w-24 ml-auto mr-auto">
                <img src="@/assets/images/img_OKYAMI.png" alt="" class="w-full h-auto drop-shadow-[0_0_16px_rgba(255,255,255,100)]">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="fixed bottom-0 left-0 right-0 z-0">
    <div class="w-dvw h-4 bg-[#3AA0FF]"></div>
  </div>

  <transition>
    <StampCodeInput v-if="isCodeModalOpen" :error="isCheckCodeError" @submit-check-code="submitCheckCode" @control-code-modal="controlCodeModal" />
  </transition>
</template>

<style scoped>
.move_okyami {
  animation: move_okyami 1.5s;
}
@keyframes move_okyami {
  0% {
    transform: translateX(0);
  }
  30% {
    transform: translateX(7px);
  }
  50% {
    transform: translateX(0px);
  }
  60% {
    transform: translateX(7px);
  }
  80% {
    transform: translateX(0px);
  }
  100% {
    transform: translateX(0);
  }
}

.v-enter-active,
.v-leave-active {
  transform: translate(0px, 0px);
  transition: transform 325ms cubic-bezier(0, 0, 0.2, 1) 0ms;
}

.v-enter-from,
.v-leave-to {
  transform: translateY(100vh) translateY(0px);
}
</style>
