import { createApp } from 'vue'
import { createPinia } from 'pinia';
import * as Sentry from "@sentry/vue";
import './style.css'
import App from './App.vue'
import router from './router'

// piniaのインポート
const pinia = createPinia()

// dayjsのインポート
import dayjs from 'dayjs'
import 'dayjs/locale/ja'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'

// ロケール設定
dayjs.locale('ja')
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.tz.setDefault('Asia/Tokyo')

const app = createApp(App)
// dayjsをprovideに設定する
app.provide('dayjs', dayjs)
app.use(pinia)
app.use(router)

Sentry.init({
  app,
  dsn: "https://875216dd4623f4a083791898e95e963f@o4507786909908992.ingest.us.sentry.io/4507786915807232",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost:5173", /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

app.mount('#app')
