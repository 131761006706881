<script setup>
const props = defineProps({
  date: {
    type: Object,
    required: true,
  },
  stampCleaDay: {
    type: Array,
    required: true,
  },
})

import { ref, inject, watch } from 'vue'

// injectからdayjsを呼び出す
const dayjs = inject('dayjs')
const calendars = ref([])

const createCalenderData = (oldStampCleaDays) => {
  const startOfMonth = props.date.startOf('month')
  const endOfMonth = props.date.endOf('month')
  calendars.value = []

  let showOrder = 0
  let _startDate = startOfMonth
  for (let i = 0; i < endOfMonth.date(); i++) {
    // もしoldStampCleaDaysにobjectの配列がある場合は、追加でスタンプを押したパターンなので、全てからのshowOrderではなく、新規に追加された要素だけにshowOrderを追加する
    const isClear = props.stampCleaDay.find((item) => dayjs(item.clear_date).tz().format('YYYY-MM-DD') === _startDate.format('YYYY-MM-DD'))

    if (oldStampCleaDays?.[0]?.length > 0) {
      // 初期表示ではない
      calendars.value.push({
        dateInfo: _startDate,
        date: _startDate.date(),
        showOrder: isClear ? showOrder + 1 : undefined,
        stamp: isClear,
      })
    } else {
      // 初期表示
      calendars.value.push({
        dateInfo: _startDate,
        date: _startDate.date(),
        showOrder: isClear ? showOrder : undefined,
        stamp: isClear,
      })
      if (isClear) showOrder += 0.3
    }

    _startDate = _startDate.add(1, 'day')
  }
}
createCalenderData()

const imgSrc = (fileName) => {
  return new URL(`../assets/images/stamps/${fileName}`, import.meta.url).href
}

watch([() => props.stampCleaDay], (value, oldValue, onCleanup) => {
  createCalenderData(oldValue)
})
</script>

<template>
  <div>
    <div class="flex flex-wrap gap-x-1 gap-y-4 justify-between">
      <div v-for="(date, index) in calendars" :key="index" style="width: calc(((100dvh - 0rem) / 11.5) - 0px); height: calc(((100dvh - 0rem) / 11.5) - 0px);" class="bg-white rounded-full text-center text-[#B8B0B0] drop-shadow-[0_1px_4px_rgba(92,176,255,100)] relative">
        {{ date.date }}
        <div v-if="date.stamp" class="w-9/12 h-auto absolute top-1/2 left-1/2 transform-gpu -translate-x-1/2 -translate-y-1/2 mix-blend-multiply">
          <img :src="imgSrc(date.stamp.stamps.file_name)" alt="スタンプ" class="move_stamp w-full h-auto" :style="{'animation-delay': date.showOrder + 's'}">
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.move_stamp {
  transform: scale(0);
  animation: move_stamp 0.5s ease-in-out forwards;
}

@keyframes move_stamp {
  0% {
    transform: scale(5);
    opacity: 0;
  }
  10% {
    opacity: 0;
  }
  60% {
    transform: scale(0.3);
    opacity: 1;
  }
  80% {
    transform: scale(0.8);
  }
  100% {
    transform: scale(1);
  }
}
</style>
