<script setup>
const props = defineProps({
  date: {
    type: Object,
    required: true,
  },
  userName: {
    type: String,
    required: true,
  },
})
</script>

<template>
  <div class="flex justify-between relative top-[-0.8rem]">
    <div class="pt-[19px]">
      <div class="overflow-hidden">
        <div class="w-[7rem] h-[7rem] text-center bg-[#FFA741] rounded-full flex flex-col items-center justify-center ml-2 relative left-0 top-[-10px]">
          <p class="text-2xl text-white">{{ props.date.year() }}<span class="text-lg">ねん</span></p>
          <p class="text-5xl text-white leading-6">{{ props.date.month() + 1 }}<span class="text-2xl">がつ</span></p>
        </div>
      </div>
    </div>

    <div class="pt-10 flex flex-col">
      <div class="relative mb-2">
        <p class="inline-block text-lg bg-[#FFF45C] pb-1 pl-3 pr-4 before:inline-block before:w-[3rem] before:h-[4rem] before:content-[''] before:bg-[url('@/assets/images/item_text-frame.svg')] before:bg-cover before:absolute before:top-[-1.7rem] before:left-[-1.4rem]">
          にゃんぴーといっしょ スタンプラリー
        </p>
      </div>
      <div class="kosugi-maru-regular inline-block ml-auto mr-0 bg-white text-right pl-7 pr-4 pt-1 pb-1">
        <p><span class="mr-1">{{ props.userName }}</span>さん</p>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>
