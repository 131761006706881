<script setup>
import Loading from '@/components/Loading.vue'

import { RouterLink } from 'vue-router'
import { supabase } from '@/utils/supabase.js'
import { useStampStore } from '@/stores/stamp'
import { ref } from 'vue'

const store = useStampStore()

const user_name = ref('')
const email = ref('')
const password = ref('')
const isEmailSent = ref(false)
const isError = ref(false)
const showLoading = ref(false)

const signUp = async () => {
  isError.value = false
  showLoading.value = true

  const { user, session, error } = await supabase.auth.signUp({
    email: email.value,
    password: password.value,
    options: {
      data: { 'user_name': user_name.value },
    },
  })
  if (error) {
    store.setLoggedIn(false)
    store.setAuth(null)
    isEmailSent.value = false

    isError.value = true
  } else {
    isEmailSent.value = true
  }

  showLoading.value = false
}
</script>

<template>
  <transition>
    <Loading v-if="showLoading" />
  </transition>

  <div class="h-full flex flex-col items-center justify-center">
    <h2 class="text-center text-6xl mb-16">とうろく</h2>
    <div class="w-11/12">
      <form class="mb-2" @submit.prevent="signUp">
        <div class="mb-4 kosugi-maru-regular">
          <label class="block text-xl">おなまえ<span class="text-sm">（8文字まで）</span></label>
          <input type="text" v-model="user_name" maxlength="8" required :class="{'border-[#ef4444]': isError}" class="kosugi-maru-regular appearance-none text-xl border-4 border-[#808080] rounded-xl w-full py-2 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" />
        </div>
        <div class="mb-4 kosugi-maru-regular">
          <label class="block text-xl">メールアドレス</label>
          <input type="email" v-model="email" required :class="{'border-[#ef4444]': isError}" class="kosugi-maru-regular appearance-none text-xl border-4 border-[#808080] rounded-xl w-full py-2 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" />
        </div>
        <div class="mb-8 kosugi-maru-regular">
          <label class="block text-xl">パスワード<span class="text-sm">（6文字以上）</span></label>
          <input type="password" v-model="password" minlength="6" required :class="{'border-[#ef4444]': isError}" class="kosugi-maru-regular appearance-none text-xl border-4 border-[#808080] rounded-xl w-full py-2 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" />
        </div>
        <div class="mb-4" v-if="isError">
          <p class="text-red-500 text-center text-2xl">なにかまちがえてるにゃ</p>
        </div>

        <div>
          <button type="submit" class="nikukyu w-full bg-gradient-to-r from-[#FFA741] to-[#FF00D6] text-white text-4xl rounded-full pt-4 pb-5">おけぴっ</button>
        </div>
      </form>
      <p class="kosugi-maru-regular text-center">ログインは<RouterLink to="Login" class="underline underline-offset-1">こちら</RouterLink></p>
    </div>
  </div>

  <transition>
    <div class="kosugi-maru-regular w-dvw h-dvh fixed top-0 left-0 bg-[rgba(0,0,0,0.8)] z-10" v-if="isEmailSent">
      <div class="h-full flex items-center justify-center">
        <div class="w-11/12 pt-10 pb-10 px-5 bg-white rounded-3xl relative">
          <p>「{{ email }}」宛にメールを送ったにゃ〜📧✨<br>本文のURLをポチっとクリックして、承認してにゃん🐾</p>
        </div>
      </div>
    </div>
  </transition>
</template>

<style scoped>
.nikukyu::after {
  display: inline-block;
  width: 2.5rem;
  height: 2.5rem;
  content: '';
  -webkit-mask-image: url('@/assets/images/img_nikukyu.svg');
  mask-image: url('@/assets/images/img_nikukyu.svg');
  mask-repeat: no-repeat;
  mask-size: contain;
  mask-position: center;
  background-color: white;
  margin-left: 0.5rem;
  margin-bottom: -0.4rem;
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
