<script setup>
import { RouterView } from 'vue-router';
import { ref, onMounted } from 'vue';

const showInstall = ref(false);
const sss = ref(null);
const text = ref('');

function disableScroll(event) {
  event.preventDefault();
}

onMounted(() => {
  document.addEventListener('touchmove', disableScroll, { passive: false });

  window.addEventListener('DOMContentLoaded', () => {
    text.value = 'browser tab';

    // PWAとしてインストールしてくれてる人
    if (window.matchMedia('(display-mode: standalone)').matches) {
      text.value = 'standalone';
    } else {
      // PWAとしてインストールしていない人なので、インストールを促す（iOS以外）
      let installEvt;
      window.addEventListener('beforeinstallprompt', (e) => {
        installEvt = e;
        e.preventDefault();

        showInstall.value = true;
        sss.value = installEvt;
      });
    }
  });
})
</script>

<template>
  <main class="h-dvh relative z-0 bg-[url('@/assets/images/bg.png')] bg-no-repeat bg-top bg-cover">
    <transition>
    <div class="w-dvw h-16 fixed top-3 left-0 right-0 bottom-0 z-40 px-1" v-if="showInstall">
      <div class="w-full h-full bg-white rounded-2xl p-3 kosugi-maru-regular">
        <div class="flex h-full items-center justify-between">
          <p class="text-sm">ホーム画面にインストールするにゃ！</p>
          <button @click="sss.prompt();" class="text-sm bg-gradient-to-r from-[#FFA741] to-[#FF00D6] text-white rounded-full pt-1 pb-1 px-3">インストール</button>
        </div>
      </div>
    </div>
    </transition>

    <RouterView />
  </main>
</template>

<style scoped>
.v-enter-active, .v-leave-active {
  transform: translate(0px, 0px);
  transition: transform 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;
}

.v-enter-from, .V-leave-to {
  transform: translateY(-100vh) translateY(0px);
}
</style>
