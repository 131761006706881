<script setup>
import Loading from '@/components/Loading.vue'

import { useRouter, RouterLink } from 'vue-router'
import { supabase } from '@/utils/supabase.js'
import { useStampStore } from '@/stores/stamp'
import { ref } from 'vue'

const store = useStampStore()
const router = useRouter()

const email = ref('')
const password = ref('')
const isError = ref(false)
const showLoading = ref(false)

const signIn = async () => {
  isError.value = false
  showLoading.value = true

  const { data, error } = await supabase.auth.signInWithPassword({
    email: email.value,
    password: password.value,
  })

  if (error) {
    store.setLoggedIn(false)
    store.setAuth(null)

    isError.value = true
  } else {
    store.setLoggedIn(true)
    store.setAuth(data.user)

    await router.push({name: 'Home'})
  }

  showLoading.value = false
}
</script>

<template>
  <transition>
    <Loading v-if="showLoading" />
  </transition>

  <div class="h-full flex flex-col items-center justify-center">
    <h2 class="text-center text-6xl mb-16">ログイン</h2>
    <div class="w-11/12">
      <form class="mb-2" @submit.prevent="signIn">
        <div class="mb-4 kosugi-maru-regular">
          <label class="block text-xl">メールアドレス</label>
          <input type="email" v-model="email" required :class="{'border-[#ef4444]': isError}" class="kosugi-maru-regular appearance-none text-xl border-4 border-[#808080] rounded-xl w-full py-2 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" />
        </div>
        <div class="mb-8 kosugi-maru-regular">
          <label>パスワード</label>
          <input type="password" v-model="password" required :class="{'border-[#ef4444]': isError}" class="kosugi-maru-regular appearance-none text-xl border-4 border-[#808080] rounded-xl w-full py-2 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" />
        </div>
        <div class="mb-4" v-if="isError">
          <p class="text-red-500 text-center text-2xl">なにかまちがえてるにゃ</p>
        </div>

        <div>
          <button type="submit" class="nikukyu w-full bg-gradient-to-r from-[#FFA741] to-[#FF00D6] text-white text-4xl rounded-full pt-4 pb-5">おけぴっ</button>
        </div>
      </form>
      <p class="kosugi-maru-regular text-center">新規登録は<RouterLink to="Register" class="underline underline-offset-1">こちら</RouterLink></p>
    </div>
  </div>
</template>

<style scoped>
.nikukyu::after {
  display: inline-block;
  width: 2.5rem;
  height: 2.5rem;
  content: '';
  -webkit-mask-image: url('@/assets/images/img_nikukyu.svg');
  mask-image: url('@/assets/images/img_nikukyu.svg');
  mask-repeat: no-repeat;
  mask-size: contain;
  mask-position: center;
  background-color: white;
  margin-left: 0.5rem;
  margin-bottom: -0.4rem;
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
