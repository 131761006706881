import { defineStore } from 'pinia';

export const useStampStore = defineStore('stamp', {
  state: () => {
    return {
      auth: {
        loggedIn: false,
        user: null,
      },
    }
  },
  actions: {
    setLoggedIn(loggedIn) {
      this.auth.loggedIn = loggedIn
    },
    setAuth(auth) {
      this.auth.user = auth
    }
  }
})
