<script setup>
</script>

<template>
  <div class="w-dvw h-dvh fixed top-0 left-0 bg-[rgba(0,0,0,0.8)] z-10">
    <div class="flex h-full items-center justify-center">
      <div class="loader"></div>
    </div>
  </div>
</template>

<style scoped>
.loader {
  width: 50px;
  padding: 8px;
  aspect-ratio: 1;
  border-radius: 50%;
  background: #FFA741;
  --_m:
      conic-gradient(#0000 10%,#000),
      linear-gradient(#000 0 0) content-box;
  -webkit-mask: var(--_m);
  mask: var(--_m);
  -webkit-mask-composite: source-out;
  mask-composite: subtract;
  animation: l3 1s infinite linear;
}
@keyframes l3 {to{transform: rotate(1turn)}}
</style>
