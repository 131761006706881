<script setup>
defineEmits(['submitCheckCode', 'controlCodeModal'])
const props = defineProps(['error'])

import { ref } from 'vue'

const code = ref('')
</script>

<template>
  <div class="w-dvw h-dvh fixed top-0 left-0 bg-[rgba(0,0,0,0.8)]">
    <div class="h-full flex items-center justify-center">
      <div style="margin-top: calc(442px + -300px);" class="w-11/12 pt-4 pb-5 px-5 bg-white rounded-3xl relative">
        <div class="absolute top-[-180px] left-0 right-0 -z-10 w-9/12 mr-auto ml-auto">
          <img src="@/assets/images/img_stapm_nyan.png" alt="" class="w-full h-auto">
        </div>

        <div class="mb-10 relative">
          <p class="text-center text-5xl"
             style="text-shadow:
                      3px 3px 1px #F9DBB3, -3px -3px 1px #F9DBB3,
                      -3px 3px 1px #F9DBB3, 3px -3px 1px #F9DBB3,
                      3px 0px 1px #F9DBB3, -3px -0px 1px #F9DBB3,
                      0px 3px 1px #F9DBB3, 0px -3px 1px #F9DBB3;"
          >あいことばで<br>スタンプおせるにゃ</p>
          <div class="absolute top-6 right-2.5 w-10">
            <img src="@/assets/images/img_nikukyu.svg" alt="" class="w-full h-auto">
          </div>
        </div>
        <div class="relative w-11/12 ml-auto mr-0 mb-2 pr-2">
          <p class="text-center drop-shadow-[0_1px_1px_rgba(0,0,0,0.25)] text-3xl bg-[#FFF45C] pb-1 pl-3 pr-4 before:inline-block before:w-[3rem] before:h-[4rem] before:content-[''] before:bg-[url('@/assets/images/item_text-frame.svg')] before:bg-cover before:absolute before:top-[-1.7rem] before:left-[-1.4rem]">
            きょうのあいことば
          </p>
        </div>
        <div class="mb-3">
          <input type="text" v-model="code" :class="{'border-[#ef4444]': props.error}" class="kosugi-maru-regular appearance-none text-xl text-center border-4 border-[#808080] rounded-xl w-full py-2 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" placeholder="ここに合言葉を入れにゃさい">
          <div v-if="props.error">
            <p class="text-red-500 text-center text-2xl">あいことばがちがうにゃ</p>
          </div>
        </div>
        <div class="mb-2">
          <button @click="$emit('submitCheckCode', code)" class="nikukyu w-full bg-gradient-to-r from-[#FFA741] to-[#FF00D6] text-white text-4xl rounded-full pt-4 pb-5">おけぴっ</button>
        </div>
        <div class="text-center">
          <button @click="$emit('controlCodeModal')" class="w-10/12 bg-gradient-to-r from-[#AEAEB2] to-[#7DADDB] text-white text-4xl rounded-full pt-3 pb-4">もどる</button>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
input::placeholder {
  text-align: center;
  font-size: 1.25rem;
  line-height: 1.25;
}

.nikukyu::after {
  display: inline-block;
  width: 2.5rem;
  height: 2.5rem;
  content: '';
  -webkit-mask-image: url('@/assets/images/img_nikukyu.svg');
  mask-image: url('@/assets/images/img_nikukyu.svg');
  mask-repeat: no-repeat;
  mask-size: contain;
  mask-position: center;
  background-color: white;
  margin-left: 0.5rem;
  margin-bottom: -0.4rem;
}
</style>
